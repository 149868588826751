import React from "react";
import { useLanguage } from "src/LanguageContext";

const LanguageSwitcher = () => {
  const {currentLanguage,selectLanguage } = useLanguage();
  return (
    <div
      className={`language-switcher-wrap`}
    >
      <div onClick={()=>selectLanguage("en")} className={`language-switcher-wrap-item ${currentLanguage === "en" ? "active" : ""}`}>
        <p>EN</p>
      </div>
      
      <div onClick={()=>selectLanguage("fr")} className={`language-switcher-wrap-item ${currentLanguage === "fr" ? "active" : ""}`}>
        <p>FR</p>
      </div>
     
    </div>
  );
};

export default LanguageSwitcher;
